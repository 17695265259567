import { call, put, all, takeLatest, delay, select } from 'redux-saga/effects'

// models
import { getProjectStatus } from 'models/api/project'

// actions
import actionTypes, {
  fetchProjectStart,
  fetchProjectSuccess,
  fetchProjectFail,
} from 'redux/project/actions'
import {
  processStatusStart,
  setCurrentProcess,
  setCurrentProcessId,
} from 'redux/process/actions'
import { fetchAccountStart } from 'redux/account/actions'

// selectors
import { selectProjectId, selectCurrentProject } from 'redux/project/selectors'
import { selectCurrentAccount } from 'redux/account/selectors'
import { selectCurrentProcessId } from 'redux/process/selectors'

// third parties
import { hotjarOnSendEvent } from 'third_parties/hotjar-initializer'

// constants
import { CURRENT_PROJECT_STORAGE_KEY } from 'constants/session_storage_keys.contants'
import { HOTJAR_EVENTS } from 'constants/hotjat_events.constants'
import { VALID_PROCESSES } from 'constants/valid_process.constants'
import { ROUTES } from 'constants/routes.constants'
import { TARGET_TYPE } from 'constants/target'

// utils
import Process from 'utils/Process'
import { clearStorageExcept } from 'utils/clearStorageExcept'

/**
 * Handles the request of the current project
 */
function* fetchProject({ payload }) {
  const projectId = yield select(selectProjectId)

  if (!projectId) return

  // tag hotjar recording with the project id only the first time the page is loaded
  const currentProject = yield select(selectCurrentProject)
  if (!currentProject) {
    hotjarOnSendEvent(HOTJAR_EVENTS.TAG_RECORDING, [`project-${projectId}`])
  }

  const { result, error } = yield getProjectStatus({ projectId })

  if (!error) {
    const { project, lastCoordinatorProcess } = result.object
    const { process, status: processStatus } = lastCoordinatorProcess
    const projectWithProcess = { ...project, process: {...process}}

    const isLoadedFromIframe = window.location !== window.parent.location

    if (isLoadedFromIframe && payload) {
      const { storedProjectIframe } = payload

      if (project.url !== storedProjectIframe.url) {
        window.open(ROUTES.DASHBOARD, TARGET_TYPE.BLANK)
        window.location.replace('/?check=false')
        clearStorageExcept(['app.settings.locale'])
        clearStorageExcept(['app.project'], 'sessionStorage')
        return
      }
    }

    yield put(fetchProjectSuccess(projectWithProcess))
    yield call(updateSessionProjectData, projectWithProcess)


    // call account after project has been saved if there's no account data stored
    const currentAccount = yield select(selectCurrentAccount)
    if (currentAccount === null) {
      yield put(fetchAccountStart())
    }

    const { result: newProcessId, error: err } = yield call(
      getCurrentProcessIdFromProject,
      process,
    )

    if (err) {
      yield put(setCurrentProcess(null))
      yield recallFetchProject()
      return
    }

    const currentProcessId = yield select(selectCurrentProcessId)

    if (currentProcessId !== newProcessId) {
      yield put(setCurrentProcessId(newProcessId))
      yield put(setCurrentProcess(null))
    }
    yield put(processStatusStart(lastCoordinatorProcess))
    yield recallFetchProject(processStatus)

  } else {
    yield put(fetchProjectFail(error))
    yield recallFetchProject()
  }
}

/**
 * Handles the interval between project calls
 */
function* recallFetchProject(currentProcess) {
  const { hasReplaceID, isProcessProcessing } = Process
  const currentProcessID = yield select(selectCurrentProcessId)

  let seconds = 10

    if (hasReplaceID(currentProcessID) && window.location.pathname === ROUTES.FIND_AND_REPLACE && isProcessProcessing(currentProcess)) {
      seconds = 2
    }

    if (isProcessProcessing(currentProcess) && (window.location.pathname === ROUTES.ASSISTANT || window.location.pathname === ROUTES.MANUAL_UPDATES)) {
      seconds = 3
    }

  yield delay(seconds * 1000)
  yield put(fetchProjectStart())
}

function updateSessionProjectData(projectData) {
  const sessionProject = JSON.parse(sessionStorage.getItem(CURRENT_PROJECT_STORAGE_KEY))
  if (sessionProject) {
    sessionProject.url = projectData.url
    sessionProject.platform = projectData.platform
    sessionStorage.setItem(CURRENT_PROJECT_STORAGE_KEY, JSON.stringify(sessionProject))
  }

  const localProject = JSON.parse(localStorage.getItem(CURRENT_PROJECT_STORAGE_KEY))
  if (localProject) {
    localProject.url = projectData.url
    localProject.platform = projectData.platform
    sessionStorage.setItem(CURRENT_PROJECT_STORAGE_KEY, JSON.stringify(localProject))
  }
}

/**
 *
 * @param {{uniqID: string, parent_process_id: string, status: string}} processes
 */
function getCurrentProcessIdFromProject(processes) {
  if (!processes)
    return { error: 'No process active', result: null }

  const validProcesses = [...VALID_PROCESSES]

  let result = null
  let error = null


      const isStandardProcess = validProcesses
        .map((type) => {
          const [processType] = processes.uniqID.split('_')
          return processType === type
        })
        .includes(true)

      if (isStandardProcess) {
        result = processes.uniqID
      } else {
        error = 'No process active'
      }

  return { result, error }
}

// Action listeners
function* onFetchProjectStart() {
  yield takeLatest(actionTypes.FETCH_PROJECT_START, fetchProject)
}

export default function* projectSaga() {
  yield all([call(onFetchProjectStart)])
}
